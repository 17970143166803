import React, { createContext, PropsWithChildren, useContext } from "react";
import { setup } from "goober";
import { useModeStore, defaultMode } from "./stores";

const light = {
  colors: {
    background: "#eee",
    text: "#222",
    dimmed: "rgba(0,0,0,.6)",
    faint: "rgba(0,0,0,.4)",
    primary: "#ff9e27",
    accent: "#4eaace",
  },
};
const dark: Theme = {
  colors: {
    background: "#57544e",
    text: "#fff",
    dimmed: "rgba(255,255,255,.6)",
    faint: "rgba(255,255,255,.4)",
    primary: "#ff9e27",
    accent: "#70deff",
  },
};

export type Theme = typeof light;

export const ThemeContext = createContext(
  defaultMode === "dark" ? dark : light
);
export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [mode] = useModeStore();

  return (
    <ThemeContext.Provider value={mode === "dark" ? dark : light}>
      {children}
    </ThemeContext.Provider>
  );
};

setup(React.createElement, undefined, useTheme);
