import React, { useEffect, useMemo, useRef } from "react";
import root from "react-shadow";

import { useApi, API } from "../api";
import { makeRender } from "../renderer";
import { setDocumentInfo, useModeStore } from "../stores";

type Props = {
  config: Config;
  profileId: string;
  templateId: string;
  themeId: string;
};

export const Doc: React.FC<Props> = ({
  config,
  profileId,
  templateId,
  themeId,
}) => {
  const rootEl = useRef<HTMLDivElement>(null);
  const [mode] = useModeStore();

  const { compile, render } = useMemo(
    () =>
      makeRender(
        (s: string) => `${API}/${s.replace("assets/", `assets/${templateId}/`)}`
      ),
    [templateId]
  );

  const templateInfo = useMemo(
    () => config.templates.find((t) => t.id === templateId),
    [config, templateId]
  );
  const themeInfo = useMemo(
    () => templateInfo && templateInfo.themes.find((t) => t.id === themeId),
    [templateInfo, themeId]
  );
  const profileInfo = useMemo(
    () => config.documents.find((p) => p.id === profileId),
    [config, profileId]
  );

  const background = templateInfo?.app?.mode?.[mode]?.documentBgColor;

  useEffect(() => {
    if (background) document.body.style.backgroundColor = background;
  }, [background]);

  // TODO: better error handling when local storage has incorrect ids
  useEffect(() => {
    let subject = "";
    if (!templateInfo) subject = "template";
    if (!themeInfo) subject = "theme";
    if (!profileInfo) subject = "profile";

    if (subject)
      throw Error(
        `No ${subject} found, please clear your Local Storage and refresh`
      );
  }, [templateInfo, themeInfo, profileInfo]);

  const { data: rendererStyles } = useApi<RendererStyles>(
    "rendererStyles",
    "/styles"
  );

  const { data: template } = useApi<Template>("template", templateInfo?.url);
  const { data: theme } = useApi<Theme>("theme", themeInfo?.url);
  const { data: profile } = useApi<ProfileDocument>(
    "profile",
    profileInfo?.url
  );

  const generateMarkup = useMemo(
    () => (template ? compile(template) : undefined),
    [template]
  );
  const markup = useMemo(
    () =>
      generateMarkup && profile ? generateMarkup(profile.data) : undefined,
    [generateMarkup, profile]
  );

  useEffect(() => {
    if (profile) {
      const { data: _, ...info } = profile;
      setDocumentInfo((state) => ({ ...state, ...info }));
    }
  }, [profile]);

  useEffect(() => {
    if (rendererStyles && templateInfo && markup && rootEl.current && theme) {
      const nPages = render({
        styles: rendererStyles,
        markup,
        pageConfig: templateInfo.page,
        background,
        root: rootEl.current,
        theme,
      });

      setDocumentInfo((state) => ({ ...state, nPages }));
    }
  }, [rendererStyles, templateInfo, markup, rootEl.current, theme, mode]);

  return (
    <root.div>
      <div id="document" ref={rootEl} />
    </root.div>
  );
};
