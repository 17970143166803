export const toN = (str: string) => Math.round(window.parseFloat(str));

export const getMesearements = (el: Element, withMargin = true) => {
  const style = window.getComputedStyle(el);
  const margin = withMargin && {
    ver: toN(style.marginBottom) + toN(style.marginTop),
    hor: toN(style.marginLeft) + toN(style.marginRight),
  };

  return {
    height: el.clientHeight + (margin ? margin.ver : 0),
    width: el.clientWidth + (margin ? margin.hor : 0),
  };
};

export const str2filename = (s: string) => s.toLowerCase().replace(" ", ".");

// wraps promise to never throw, return tuple with error/data instead
export const prom = <T>(
  p: Promise<T> | (() => Promise<T>)
): Promise<
  readonly [error: null, result: T] | readonly [error: string, result: null]
> =>
  (typeof p === "function" ? p() : p)
    .then((res) => {
      if (res instanceof Response && !res.ok)
        throw Error(`${res.status}: ${res.statusText}`);

      return [null, res] as const;
    })
    .catch((err) => {
      let msg = "";
      if (err instanceof Error) msg = err.message;

      try {
        msg = msg || JSON.stringify(err);
      } catch (error) {
        msg = String(err);
      }

      return [msg, null] as const;
    });
