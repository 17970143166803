import { styled } from "goober";

type SpacerProps = { type: "ver" | "hor"; size?: string };

export const Spacer = styled("div")<SpacerProps>(({ type, size = "2rem" }) =>
  type === "hor"
    ? `
        display: inline-block;
        width: ${size};
      `
    : `
        height: ${size};
      `
);
