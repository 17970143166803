import { keyframes, styled } from "goober";
import React, { useState } from "react";
import { iconDownload } from "./Icons";
import { prom } from "../utils/helpers";

type Props = {
  color?: string;
  filename: string;
  url: string;
};

const Button = styled("button")<{ color?: string; loading: number }>`
  position: absolute;
  right: 10px;
  bottom: calc(100% + 10px);
  padding: 12px;
  display: flex;
  border-radius: 50%;
  background: ${(p) => p.color};
  color: inherit;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    position: relative;
    top: -2px;

    path {
      fill: currentColor;
    }
  }

  ${(p) =>
    p.loading
      ? `

          pointer-events: none;
          cursor: default;

          > svg path:nth-child(1) {
            display: none;
          }
        `
      : `
          &:hover svg path {
            fill: ${p.theme.colors.text};
          }
        `}
`;

const arrowAnimation = keyframes`
  from {
    transform: translate(-50%, -25px);
  } to {
    transform: translate(-50%, 20px);
  }
`;

const AnimatedArrow = styled("span")`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 29px;
  overflow: hidden;

  svg {
    position: absolute;
    left: 50%;
    top: 10px;
    animation: ${arrowAnimation} linear 1s infinite;
    animation-delay: -0.55s;

    path:nth-child(2) {
      display: none;
    }
  }
`;

const Progress = styled("span", React.forwardRef)`
  position: absolute;
  z-index: -1;
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  border-radius: 50%;
  background: conic-gradient(
    #fff var(--download-progress),
    transparent var(--download-progress)
  );
  transition: --download-progress 0.3s ease-in-out;
`;

const isJson = (str: string) => str.startsWith(";json;");
const parse = (str: string) =>
  str
    .split(";json;")
    .filter(Boolean)
    .map((s) => JSON.parse(s)) as Array<DownloadProgress | DownloadError>;

export const DownloadButton = ({ url, color, filename }: Props) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const finish = (error?: string) => {
    setLoading(false);
    setProgress(0);

    if (error) alert(error);
  };

  const download = async () => {
    setLoading(true);

    const decoder = new TextDecoder();
    const [error, res] = await prom(fetch(url));

    if (error) return finish(error);

    let chunks: Uint8Array[] = [];
    let errorMessage = "";

    try {
      for await (const chunk of res!.body!) {
        const text = decoder.decode(chunk);

        if (!isJson(text)) {
          // if it's not json, it's a pdf chunk
          chunks.push(chunk);
          setProgress((p) => (p < 90 ? p + 5 : p));
          continue;
        }

        for (const data of parse(text)) {
          if ("progress" in data) setProgress(Math.round(data.progress * 100));
          else {
            errorMessage = data.error;
            break;
          }
        }
      }
    } catch (err) {
      errorMessage =
        err instanceof Error ? err.message : "Something went wrong...";
    }

    if (errorMessage) return finish(errorMessage);

    setProgress(100);

    // Let animation play out to 100%
    setTimeout(() => {
      finish();

      const objectUrl = URL.createObjectURL(
        new Blob(chunks, { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(objectUrl);
    }, 300);
  };

  return (
    <Button
      color={color}
      loading={loading ? 1 : 0}
      disabled={loading}
      onClick={download}
    >
      {iconDownload}
      {loading && (
        <>
          <AnimatedArrow>{iconDownload}</AnimatedArrow>
          <Progress
            style={
              {
                "--download-progress": `${progress}%`,
              } as React.CSSProperties
            }
          />
        </>
      )}
    </Button>
  );
};
