import { useEffect, useRef } from "react";

export const useStickyHeader = () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) throw Error("No element to use as sticky header");

    const el = ref.current;
    let resizeTimer: number;
    let size = el.getBoundingClientRect();
    let prevY = window.scrollY;
    let y = prevY;
    let elY = 0;

    const loop = () => {
      y = Math.round(window.scrollY);
      if (y === prevY) return;

      const dir = prevY > y ? "up" : "down";

      if (dir === "down" && elY <= size.height && y > 0) {
        elY = elY + (y - prevY);
        el.style.transform = `translateY(-${Math.round(elY)}px)`;
      }

      if (dir === "up" && elY >= 0) {
        if (elY > size.height) elY = size.height;

        elY = elY - (prevY - y);
        el.style.transform = `translateY(-${Math.round(elY > 0 ? elY : 0)}px)`;
      }

      prevY = y;

      window.requestAnimationFrame(loop);
    };

    const scroll = () => window.requestAnimationFrame(loop);

    const getRect = () => {
      window.clearTimeout(resizeTimer);
      resizeTimer = window.setTimeout(() => {
        size = el.getBoundingClientRect();
      }, 250);
    };

    window.addEventListener("scroll", scroll);
    window.addEventListener("resize", getRect);

    const observer = new MutationObserver(() => {
      getRect();
    });

    observer.observe(el, { subtree: true, childList: true });

    return () => {
      window.removeEventListener("scroll", scroll);
      window.removeEventListener("resize", getRect);
      window.clearTimeout(resizeTimer);
      observer.disconnect();
    };
  }, []);

  return ref;
};

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current as T;
};
