import {
  useCallback,
  useLayoutEffect,
  useState,
  useSyncExternalStore,
} from "react";

type ShouldUpdate<T> = (oldState: T, newState: T) => boolean;

type Listeners<T> = {
  forceUpdate: () => void;
  shouldUpdate?: ShouldUpdate<T>;
};

type Updater<T> = (state: T) => T;

const useForceUpdate = () => {
  const [, updateComponent] = useState({});

  return useCallback(() => {
    updateComponent({});
  }, []);
};

type Config<T> = {
  defaultValue: T;
  storageKey?: string;
};

const isFunc = <T>(f: T | Updater<T>): f is Updater<T> =>
  typeof f === "function";

const usedKeys = new Set<string>();

export const clearStores = () => {
  usedKeys.forEach((key) => window.localStorage.removeItem(key));
};

export const createPersistentStore = <T>({
  defaultValue,
  storageKey: key,
}: Config<T>) => {
  if (key) usedKeys.add(key);

  const storage = {
    get: () =>
      key
        ? ((JSON.parse(window.localStorage.getItem(key)!) ?? defaultValue) as T)
        : defaultValue,
    set: (value: T) =>
      key && window.localStorage.setItem(key, JSON.stringify(value)),
    remove: () => key && window.localStorage.removeItem(key),
  };

  const storeValue = storage.get();

  let state = key ? storeValue : defaultValue;
  let listeners: Listeners<T>[] = [];

  if (key && !storeValue && defaultValue) storage.set(defaultValue);

  const updateStore = (stateOrFunc: T | Updater<T>) => {
    const oldState = state;
    state = isFunc(stateOrFunc) ? stateOrFunc(state) : stateOrFunc;

    listeners.forEach(({ forceUpdate, shouldUpdate = () => true }) => {
      if (shouldUpdate(oldState, state)) forceUpdate();
    });

    if (key) {
      if (state) storage.set(state);
      else storage.remove();
    }
  };

  const useStore = (shouldUpdate?: ShouldUpdate<T>) => {
    const forceUpdate = useForceUpdate();

    useLayoutEffect(() => {
      const listener: Listeners<T> = {
        forceUpdate,
        shouldUpdate,
      };

      listeners = [...listeners, listener];

      return () => {
        listeners = listeners.filter((l) => l !== listener);
      };
    }, []);

    return [state, updateStore] as const;
  };

  return [useStore, updateStore] as const;
};

type Listener<T> = (state: T) => void;
type Setter<T> = (state: T) => T;

export const createStore = <T>(initialState: T) => {
  let state = initialState;

  const listeners = new Set<Listener<T>>();

  const store = {
    getState: () => state,
    setState: (newState: T | Setter<T>) => {
      state =
        typeof newState === "function"
          ? (newState as Setter<T>)(state)
          : newState;
      listeners.forEach((listener) => listener(state));
    },
    subscribe: (listener: Listener<T>) => {
      listeners.add(listener);
      return () => listeners.delete(listener);
    },
  };

  return {
    useStore: () =>
      [
        useSyncExternalStore(store.subscribe, store.getState),
        store.setState,
      ] as const,
    set: store.setState,
  };
};
