import React, { PropsWithChildren } from "react";
import { styled } from "goober";

const Container = styled("label")<Pick<Props, "disabled">>`
  color: ${({ disabled }) => (disabled ? "#888" : "#444")};

  .title {
    color: ${(p) => p.theme.colors.text};
    font-size: 1.4rem;
    display: inline-block;
    margin-right: 0.8rem;
  }

  .select {
    background: #fff;
    position: relative;
    display: inline-block;
  }

  select {
    appearance: none;
    border: 0;
    outline: none;
    background-color: transparent;
    padding: 0.6rem 1rem;
    padding-right: 4rem;
    font-size: 1.4rem;
    color: currentColor;
    line-height: 1.5;
  }

  .arrow {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 3rem;
    pointer-events: none;

    svg {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 1rem;
      color: inherit;
    }

    path {
      fill: currentColor;
    }
  }
`;

type Props = {
  disabled?: boolean;
  label?: string;
  value?: string;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
};

export const Dropdown: React.FC<PropsWithChildren<Props>> = ({
  children,
  disabled,
  label,
  onChange,
  value,
}) => {
  return (
    <Container disabled={disabled}>
      {label && <span className="title">{label}:</span>}
      <span className="select">
        <select disabled={disabled} onChange={onChange} value={value}>
          {children}
        </select>
        <span className="arrow">
          <svg version="1.1" viewBox="0 0 1101 750">
            <path d="M1101 199L551 750 0 199 198 0l353 353L904 0z" />
          </svg>
        </span>
      </span>
    </Container>
  );
};
