import { useEffect, useState } from "react";
import { QueryClient, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { setRequestCounter } from "../stores";

export const API =
  process.env.API ||
  (process.env.NODE_ENV !== "production" ? "http://localhost:9000" : "");

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const FAKE_DELAY = false; // for dev purposes

let bust = false;

export const bustCache = () => {
  bust = true;
  return queryClient.invalidateQueries().finally(() => (bust = false));
};

export const useCacheBuster = () => {
  const [fetching, bust] = useState(false);

  useEffect(() => {
    if (fetching) bustCache().finally(() => bust(false));
  }, [fetching]);

  return [fetching, () => bust(true)] as const;
};

const request = <T>(
  url: string | undefined,
  init: RequestInit = {}
): Promise<T> => {
  setRequestCounter((n) => ++n);

  return fetch(`${API}${url}`, {
    ...init,
    headers: { ...init.headers, cache: bust ? "reload" : "default" },
  })
    .then((res) => {
      if (!res.ok) throw res;

      const isText = res.headers.get("Content-Type")?.startsWith("text/plain");

      return isText ? res.text() : res.json();
    })
    .then((res) =>
      FAKE_DELAY
        ? new Promise<T>((resolve) => {
            setTimeout(
              () => resolve(res),
              Math.floor(Math.random() * (2000 - 500 + 1) + 500)
            );
          })
        : res
    )
    .finally(() => {
      setRequestCounter((n) => (n === 0 ? 0 : --n));
    });
};

export const useApi = <T>(
  query: string,
  url?: string,
  config?: UseQueryOptions<T, unknown, T, Array<string>>
) => {
  return useQuery({
    queryKey: [query, url ?? ""],
    queryFn: () => request<T>(url),
    enabled: Boolean(url),
    staleTime: Infinity,
    ...(config || {}),
  });
};
