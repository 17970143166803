import { createStore, createPersistentStore } from "../utils/store";

type DocumentInfoState = {
  nPages: number;
} & Omit<ProfileDocument, "data">;

export const { useStore: useRequestCounterStore, set: setRequestCounter } =
  createStore(0);
export const { useStore: useDocumentInfoStore, set: setDocumentInfo } =
  createStore<DocumentInfoState>({ chars: 0, words: 0, nPages: 0 });

export const [useTemplateStore, setTemplate] = createPersistentStore<
  [string, string] | []
>({ storageKey: "ProfilerTemplate", defaultValue: [] });

export const [useDocStore, setDoc] = createPersistentStore<string | undefined>({
  storageKey: "ProfilerProfile",
  defaultValue: undefined,
});

export const defaultMode =
  JSON.parse(window.localStorage.getItem("ProfilerMode")!) ??
  (window.matchMedia?.("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light");

export const [useModeStore, setMode] = createPersistentStore<"dark" | "light">({
  storageKey: "ProfilerMode",
  defaultValue: defaultMode,
});
