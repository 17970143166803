import React, { useEffect, useRef, useState } from "react";
import { styled } from "goober";

import { useRequestCounterStore } from "../stores";
import { usePrevious } from "../hooks";

const Container = styled("div")`
  position: fixed;
  z-index: 100;
  height: 2px;
  width: 100%;
  display: flex;

  @media print {
    display: none;
  }
`;

const Bar = styled("div", React.forwardRef)`
  height: 100%;
  width: 0;
  opacity: 0;
  background: ${(p) => p.theme.colors.accent};
`;

const TRANSITION_TIME = 400;
const BASE_TRANSITION = {
  transition: `all ${TRANSITION_TIME}ms ease-in-out`,
};
const END_TRANSITION = {
  transition: `width ${TRANSITION_TIME}ms ease-in-out, opacity ${
    TRANSITION_TIME * 2
  }ms ${TRANSITION_TIME}ms`,
};

export const Loader = () => {
  const timer = useRef(0);
  const [count] = useRequestCounterStore();
  const [style, setStyle] = useState<React.CSSProperties>(BASE_TRANSITION);

  const progress = count ? Math.round((1 - count / (count + 3)) * 100) : 100;
  const prevProgress = usePrevious(progress);

  const state =
    typeof prevProgress === "undefined"
      ? "init"
      : progress < 100 && prevProgress === 100
      ? "start"
      : progress === 100 && (prevProgress < 100 || prevProgress === 100)
      ? "end"
      : "running";

  useEffect(() => {
    window.clearTimeout(timer.current);

    if (state === "init") return;

    if (state === "start") setStyle({ ...BASE_TRANSITION, opacity: 1 });
    else if (state === "end" && style.opacity) {
      setStyle(END_TRANSITION);

      timer.current = window.setTimeout(() => {
        setStyle({ width: 0 });
      }, TRANSITION_TIME * 3);
    }

    return () => window.clearTimeout(timer.current);
  }, [state]);

  return (
    <Container>
      <Bar style={{ width: `${state === "init" ? 0 : progress}%`, ...style }} />
    </Container>
  );
};
