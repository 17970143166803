import React, { useMemo, useEffect } from "react";
import { styled, keyframes } from "goober";

import { useTemplateStore, useDocStore, useModeStore } from "../stores";
import { useCacheBuster, API } from "../api";
import { useStickyHeader } from "../hooks";

import { Dropdown } from "./Dropdown";
import { Spacer } from "./Atoms";
import { iconDark, iconLight, iconReload } from "./Icons";
import { StatusBar } from "./StatusBar";

const Container = styled("div", React.forwardRef)<{ background?: string }>`
  background: ${(p) => p.background ?? "rgba(0, 0, 0, 0.3)"};
  padding: 1rem;
  padding-right: 0;
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;

  @media print {
    display: none;
  }
`;

const Left = styled("div")`
  margin-right: auto;
  display: flex;
  align-items: center;
`;

const Logo = styled("img")<{ mode: "dark" | "light" }>`
  width: 22px;
  margin-right: 1.5rem;
  filter: grayscale(1)
    ${(p) => (p.mode === "dark" ? "brightness(100)" : "brightness(0)")};
`;

const spinFrames = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Button = styled("button")<{ spin?: 0 | 1 }>(
  (p) => `
margin: 0 0.3rem;

&:hover, &:disabled {
  opacity: 0.5;
  cursor: pointer;
}

& #icon-reload {
  width: 20px;
  height: 20px;
  margin-left: 1rem;
  animation: ${p.spin ? spinFrames : "none"} linear 0.6s infinite;
}
`
);

type Props = {
  templates: TemplatesInfo;
  profiles: DocumentsInfo;
};

const getTemplateObjById = (templates: TemplatesInfo, id?: string) =>
  id ? templates.find((t) => t.id === id) : undefined;

export const Settings: React.FC<Props> = ({ templates, profiles }) => {
  const stickyRef = useStickyHeader();
  const [[template, templateTheme], setTemplate] = useTemplateStore();
  const [doc, setDoc] = useDocStore();
  const [mode, setMode] = useModeStore();
  const [busting, bustCache] = useCacheBuster();

  const isDark = mode === "dark";

  const current = useMemo(
    () => getTemplateObjById(templates, template),
    [templates, template]
  );

  const setTemplateWithFallback = (template?: string, theme?: string) => {
    const obj = getTemplateObjById(templates, template);

    if (obj && template) {
      const defaultTheme = obj.themes[0].id;

      setTemplate([
        template,
        theme
          ? obj.themes.find((t) => t.id === theme)
            ? theme
            : defaultTheme
          : defaultTheme,
      ]);
    } else setTemplate([templates[0].id, templates[0].themes[0].id]);
  };

  useEffect(() => {
    if (!template) setTemplateWithFallback();
    if (!doc && profiles.length) setDoc(profiles[0].id);
  }, [template]);

  const selectedProfile = useMemo(
    () => profiles.find((p) => p.id === doc),
    [doc, profiles]
  );

  return (
    <>
      <Container
        ref={stickyRef}
        id="settings"
        background={current?.app?.mode?.[mode]?.settingsBgColor}
      >
        <Left>
          <Logo src={`${API}/assets/logo.svg`} mode={mode} />
          <Dropdown
            disabled={profiles.length < 2}
            label="Profile"
            value={doc}
            onChange={(e) => setDoc(e.target.value)}
          >
            {profiles.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Dropdown>
        </Left>
        <Dropdown
          disabled={templates.length < 2}
          label="Template"
          value={template}
          onChange={(e) => setTemplateWithFallback(e.target.value)}
        >
          {templates.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </Dropdown>
        <Spacer type="hor" />
        <Dropdown
          disabled={!current || current.themes.length < 2}
          label="Theme"
          value={templateTheme}
          onChange={(e) => setTemplate([template!, e.target.value])}
        >
          {current &&
            current.themes.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
        </Dropdown>
        <Button
          disabled={Boolean(busting)}
          onClick={() => bustCache()}
          spin={busting ? 1 : 0}
        >
          {iconReload}
        </Button>
        <Button onClick={() => setMode(isDark ? "light" : "dark")}>
          {isDark ? iconDark : iconLight}
        </Button>
      </Container>
      <StatusBar
        background={current?.app?.mode?.[mode]?.settingsBgColor}
        template={template}
        templateTheme={templateTheme}
        selectedProfileName={selectedProfile?.name}
      ></StatusBar>
    </>
  );
};
