import React from "react";
import { styled } from "goober";

const SvgRoot = styled("svg")`
  path {
    fill: ${(p) => p.theme.colors.text};
  }
`;

const Svg: React.FC<React.SVGProps<SVGSVGElement>> = ({
  children,
  ...props
}) => (
  <SvgRoot
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    {children}
  </SvgRoot>
);

export const iconLight = (
  <Svg>
    <svg />
    <path d="M16 22v0c-3.314 0-6-2.686-6-6s2.686-6 6-6c3.314 0 6 2.686 6 6s-2.686 6-6 6zM16 20c2.209 0 4-1.791 4-4s-1.791-4-4-4c-2.209 0-4 1.791-4 4s1.791 4 4 4v0z"></path>
  </Svg>
);

export const iconDark = (
  <Svg>
    <path d="M16 22c3.314 0 6-2.686 6-6s-2.686-6-6-6c-3.314 0-6 2.686-6 6s2.686 6 6 6v0z"></path>
  </Svg>
);

export const iconReload = (
  <Svg id="icon-reload">
    <path d="M28,16c-1.219,0-1.797,0.859-2,1.766C25.269,21.03,22.167,26,16,26c-5.523,0-10-4.478-10-10S10.477,6,16,6 c2.24,0,4.295,0.753,5.96,2H20c-1.104,0-2,0.896-2,2s0.896,2,2,2h6c1.104,0,2-0.896,2-2V4c0-1.104-0.896-2-2-2s-2,0.896-2,2v0.518  C21.733,2.932,18.977,2,16,2C8.268,2,2,8.268,2,16s6.268,14,14,14c9.979,0,14-9.5,14-11.875C30,16.672,28.938,16,28,16z" />
  </Svg>
);

export const iconPrint = (
  <Svg id="icon-print" viewBox="0 0 48 48">
    <path d="M38 16h-28c-3.31 0-6 2.69-6 6v12h8v8h24v-8h8v-12c0-3.31-2.69-6-6-6zm-6 22h-16v-10h16v10zm6-14c-1.11 0-2-.89-2-2s.89-2 2-2c1.11 0 2 .89 2 2s-.89 2-2 2zm-2-18h-24v8h24v-8z" />
  </Svg>
);

export const iconDownload = (
  <Svg id="icon-download" viewBox="0 0 512 512">
    <path
      d="M243.591,309.362c3.272,4.317,7.678,6.692,12.409,6.692c4.73,0,9.136-2.376,12.409-6.689l89.594-118.094
		c3.348-4.414,4.274-8.692,2.611-12.042c-1.666-3.35-5.631-5.198-11.168-5.198H315.14c-9.288,0-16.844-7.554-16.844-16.84V59.777
		c0-11.04-8.983-20.027-20.024-20.027h-44.546c-11.04,0-20.022,8.987-20.022,20.027v97.415c0,9.286-7.556,16.84-16.844,16.84
		h-34.305c-5.538,0-9.503,1.848-11.168,5.198c-1.665,3.35-0.738,7.628,2.609,12.046L243.591,309.362z"
    />
    <path
      d="M445.218,294.16v111.304H66.782V294.16H0v152.648c0,14.03,11.413,25.443,25.441,25.443h461.118
		c14.028,0,25.441-11.413,25.441-25.443V294.16H445.218z"
    />
  </Svg>
);
